import React from 'react';
import { Box, Button, Flex } from 'theme-ui';
import HubspotForm from 'react-hubspot-form';
import ModalBase from '../..';
import { NewsletterModalProps } from './types';

const NewsletterModal: React.FC<NewsletterModalProps> = ({
  onClose,
  portalId,
  formId,
  onSubmit,
  onReady,
  ...props
}) => (
  <ModalBase isDark {...{ onClose, ...props }}>
    <Flex variant="modal.newsletter.heading">
      <Button variant="modal.newsletter.heading.close" onClick={onClose}>
        <span>Close</span>
      </Button>
    </Flex>
    <Box variant="modal.newsletter.content">
      {!!formId && (
        <HubspotForm
          loading={<div>Loading...</div>}
          {...{ portalId, formId, onSubmit, onReady }}
        />
      )}
    </Box>
  </ModalBase>
);

export default NewsletterModal;
