/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useContext, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { HighlightProps } from './types';
import { RevealAnimationContext } from '../RevealAnimationWrapper/context';

const variants = {
  visible: (custom: number) => ({
    backgroundPosition: '-100% 0',
    transition: { ease: 'easeOut', duration: 1, delay: (custom - 1) * 1 },
  }),
  hidden: { backgroundPosition: '0 0' },
};

const Highlight: React.FC<HighlightProps> = ({ children, ...props }) => {
  const controls = useAnimation();
  const isVisible = useContext(RevealAnimationContext);

  useEffect(() => {
    if (isVisible || isVisible === undefined) {
      controls.start('visible');
    }
  }, [controls, isVisible]);

  return (
    <motion.span
      sx={{ variant: 'text.accent' }}
      animate={controls}
      initial="hidden"
      {...{ variants, ...props }}
    >
      {children}
    </motion.span>
  );
};

export default Highlight;
