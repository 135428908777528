/** @jsx jsx */
import React from 'react';
import { jsx, Box, Container, Text, Button } from 'theme-ui';
import useToggle from '../../../hooks/useToggle';
import RevealAnimationWrapper from '../../atoms/RevealAnimationWrapper';
import NewsletterModal from '../../molecules/Modal/templates/NewsletterModal';
import { InfoBannerProps } from './types';

const InfoBanner: React.FC<InfoBannerProps> = ({ items, modal }) => {
  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  return (
    <React.Fragment>
      <RevealAnimationWrapper>
        <Box variant="infoBanner.wrapper">
          <Container>
            {items.map(({ key, title, content }) => (
              <Box variant="infoBanner.row" {...{ key }}>
                <Text as="h2" variant="tags" mb={['0.5rem', '', '1rem']}>
                  {title}
                </Text>
                {content}
              </Box>
            ))}
            {modal && (
              <Button variant="links.underlinedLight" onClick={toggleOn}>
                Subscribe
              </Button>
            )}
          </Container>
        </Box>
      </RevealAnimationWrapper>
      {modal && isOpen && <NewsletterModal {...modal} onClose={toggleOff} />}
    </React.Fragment>
  );
};

export default InfoBanner;
